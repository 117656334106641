import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    loadingElement: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"


    },
}));

const Loading = (props) => {
    const classes = useStyles();
        return (
            <div className={classes.loadingElement}><CircularProgress/></div>
        )
    }
;

export default Loading;
