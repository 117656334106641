import React from 'react';
import Page from "../../Navigation/Page";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({

    main: {
        backgroundImage: 'url("main-logo.png")',
        maxHeight: 400,
        minHeight: 200,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '100%'

    },
    box: {
        padding: 20,
        textAlign: 'left',
        fontWeight: 'bold',


    },
}));


const MainPage = () => {
        const classes = useStyles();
        return (
            <Page>
                <div className={classes.main}>


                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.box}>
                            <Typography variant={'h6'}>Train harder!</Typography>
                            <Typography variant={'body1'}>
                                Change it up and load it up. Use the best exercises and workouts from
                                YouTube to maximise your gains!
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.box}>
                            <Typography variant={'h6'}>Mobile friendly easy access</Typography>
                            <Typography variant={'body1'}>
                                Video clips for every exercise with instructions from the creator, easily accessible on
                                your smartphone.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item  sm={3}>

                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.box}>
                            <Typography variant={'h6'}>No more excuses!</Typography>
                            <Typography variant={'body1'}>
                                Browse the exercises and workouts and choose what you want. Then train like a beast.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item  sm={3}>

                    </Grid>

                </Grid>


            </Page>
        )
    }
;

export default MainPage;
