import React, {useState, useCallback, useEffect, Suspense} from 'react';
import MainPage from './Pages/MainPage/MainPage';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
//import Exercises from "./Pages/Exercises/Exercises";
//import Exercise from "./Pages/Exercise/Exercise";


//import Workouts from "./Pages/Workouts/Workouts";
//import Workout from "./Pages/Workout/Workout";
//import About from "./Pages/About/About";
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {AuthContext} from "./Context/AuthContext";
//import Auth from './Pages/Auth/Auth';
//import AddExercise from "./Pages/AddExercise/AddExercise";
//import AddWorkout from "./Pages/AddWorkout/AddWorkout";
import Default from "./Pages/404/default";
import Loading from "./UI Elements/Loading";
//import AddAuthor from "./Pages/AddAuthor/AddAuthor";
//import StartWorkout from "./Pages/StartWorkout/StartWorkout";
//import Cleanup from "./Pages/Cleanup/Cleanup";
import ScrollToTop from "./UI Elements/ScrollToTop";

const Workouts = React.lazy(() => import("./Pages/Workouts/Workouts"));
const About = React.lazy(() => import("./Pages/About/About"));
const Workout = React.lazy(() => import("./Pages/Workout/Workout"));
const Auth = React.lazy(() => import("./Pages/Auth/Auth"));
const AddExercise = React.lazy(() => import("./Pages/AddExercise/AddExercise"));
const AddWorkout = React.lazy(() => import("./Pages/AddWorkout/AddWorkout"));
const Cleanup = React.lazy(() => import("./Pages/Cleanup/Cleanup"));
const StartWorkout = React.lazy(() => import("./Pages/StartWorkout/StartWorkout"));
const Exercises = React.lazy(() => import("./Pages/Exercises/Exercises"));
const AddAuthor = React.lazy(() => import("./Pages/AddAuthor/AddAuthor"));
const Exercise = React.lazy(() => import("./Pages/Exercise/Exercise"));


let theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#1565c0',
            light: '#5e92f3',
            dark: '#003c8f'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#ffb04c',
            main: '#f57f17',
            dark: '#bc5100'
            // dark: will be calculated from palette.secondary.main,

        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
        text: {
            secondary: '#000000'
        }
    },
    typography: {
        fontFamily: 'Roboto Slab'
    },

});
theme = responsiveFontSizes(theme);


const App = (props) => {
        const [userLevel, setUserLevel] = useState('anonymous');
        const [token, setToken] = useState('');


        const login = useCallback((token, username, userlevel) => {
            setUserLevel(userlevel);

            setToken(token);
            localStorage.setItem(
                'userData',
                JSON.stringify({username: username, token: token, userlevel: userlevel})
            );


        }, []);

        useEffect(() => {
            const storedData = JSON.parse(localStorage.getItem('userData'));
            if (storedData && storedData.token) {
                console.log('Login successful');
                login(storedData.token, storedData.username, storedData.userlevel)

            }
        }, [login])

        const logout = useCallback(() => {
            setUserLevel('anonymous');
            setToken(null)
            localStorage.removeItem('userData');

        }, []);

        let routes;

        if (userLevel === 'admin') {
            routes = (
                <Switch>
                    <Route path={"/startworkout/:id"}>
                        <StartWorkout/>
                    </Route>
                    <Route path={"/exercises"}>
                        <Exercises/>
                    </Route>
                    <Route path={"/exercise/:id"}>

                        <Exercise/>
                    </Route>
                    <Route exact path={"/workouts"}>
                        <Workouts/>
                    </Route>
                    <Route path={"/workout/:id"}>
                        <Workout/>
                    </Route>
                    <Route path={"/about"}>
                        <About/>
                    </Route>
                    <Route path={"/editExercise/:id"}>
                        <AddExercise/>
                    </Route>
                    <Route path={"/addExercise"}>
                        <AddExercise/>
                    </Route>

                    <Route path={"/editWorkout/:id"}>
                        <AddWorkout/>
                    </Route>
                    <Route path={"/addWorkout"}>
                        <AddWorkout/>
                    </Route>
                    <Route path={"/cleanup"}>
                        <Cleanup/>
                    </Route>
                    <Route path={"/addAuthor"}>
                        <AddAuthor/>
                    </Route>
                    <Route exact path={"/"}>
                        <MainPage/>
                    </Route>
                    <Route>
                        <Default/>
                    </Route>


                </Switch>
            );

        } else {
            routes = (
                <Switch>
                    <Route path={"/startworkout/:id"}>
                        <StartWorkout/>
                    </Route>
                    <Route exact path={"/"}>
                        <MainPage/>
                    </Route>
                    <Route path={"/exercises"}>

                        <Exercises/>

                    </Route>
                    <Route path={"/exercise/:id"}>

                        <Exercise/>
                    </Route>

                    <Route exact path={"/workouts"}>

                        <Workouts/>
                    </Route>
                    <Route path={"/workout/:id"}>

                        <Workout/>
                    </Route>
                    <Route path={"/about"}>

                        <About/>
                    </Route>
                    <Route path={"/login"}>

                        <Auth/>
                    </Route>
                    <Route>
                        <Default/>
                    </Route>


                </Switch>
            );
        }

        return (

            <AuthContext.Provider value={{userLevel: userLevel, login: login, logout: logout, token: token}}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <ScrollToTop/>
                        <Suspense fallback={<div><Loading/></div>}>
                            {routes}
                        </Suspense>
                    </Router>
                </ThemeProvider>
            </AuthContext.Provider>
        )
    }
;

export default App;


