import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Container from "@material-ui/core/Container";
import {NavLink} from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {AuthContext} from "../Context/AuthContext";

import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {faDumbbell} from "@fortawesome/pro-duotone-svg-icons";
import {useHistory} from 'react-router-dom';



//import classes from './Page.module.css';


const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
        },
        pageTitle: {
            marginRight: theme.spacing(5),

        },
        menuItem: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                fontSize: 14,
                marginRight: theme.spacing(1)

            }
            ,
            fontWeight: 500,
            cursor: 'pointer',


            '&:hover':
                {

                }
        },
        menuItemActive: {
            color: theme.palette.secondary.main,
        },
        logo: {
            padding: 10
        },
        right: {
            width: '100%',
            textAlign: "right"
        },
        appBar: {
            marginBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            minHeight: 70,

        },
        title: {
            flexGrow: 1,
        },
        toolbar: {
            paddingTop: theme.spacing(1),
        },

        naviButton: {
            marginRight: theme.spacing(1)
        },
        appBarBottom: {
            top: 'auto',
            bottom: 0,
        },

    }))
;


export default function Page(props) {
    const classes = useStyles();

    let history = useHistory();

    const auth = useContext(AuthContext);

    const logoutHandler = () => {
        auth.logout();
        setAnchorEl(null);
        history.push("/");
    }


    const MenuItemManual = (props) => {

        return (
            <Link
                color={"inherit"}
                component={NavLink} to={props.to}
                className={props.classes}
                underline={'none'}
                activeClassName={props.active}
                exact={props.exact}
                onClick={props.onClick}
            >
                {props.caption}
            </Link>
        )
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (!props.type) {


        return (
            <>

                <AppBar position="sticky" className={classes.appBar}>
                    <Container maxWidth="md">
                        <Toolbar disableGutters variant={"dense"} className={classes.toolbar}>


                            <MenuItemManual exact active={classes.menuItemActive} caption={(
                                <FontAwesomeIcon icon={faDumbbell} size={"2x"} color={"secondary"}/>
                            )} to={'/'}
                                            classes={classes.menuItem}/>
                            <MenuItemManual active={classes.menuItemActive} caption={'Exercises'} to={'/exercises'}
                                            classes={classes.menuItem}/>
                            <MenuItemManual active={classes.menuItemActive} caption={'Workouts'} to={'/workouts'}
                                            classes={classes.menuItem}/>
                            <MenuItemManual active={classes.menuItemActive} caption={'About'} to={'/about'}
                                            classes={classes.menuItem}/>
                            <div className={classes.right}>
                                {auth.userLevel === 'user' && (
                                    <>
                                        <Link color={"inherit"}
                                              aria-controls="simple-menu"
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className={classes.menuItem}
                                              underline={'none'}
                                        >
                                            <AccountCircleIcon/>
                                        </Link>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem onClick={handleClose}>Profile</MenuItem>
                                            <MenuItem onClick={handleClose}>My account</MenuItem>
                                            <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                                        </Menu>
                                    </>
                                )}

                                {auth.userLevel === 'admin' && (
                                    <>
                                        <Link color={"inherit"}
                                              aria-controls="simple-menu"
                                              aria-haspopup="true"
                                              onClick={handleClick}
                                              className={classes.menuItem}
                                              underline={'none'}
                                        >
                                            <AccountCircleIcon/>
                                        </Link>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            <MenuItem component={NavLink} to={'/addExercise'} onClick={handleClose}>Add
                                                Exercise</MenuItem>
                                            <MenuItem component={NavLink} to={'/addWorkout'} onClick={handleClose}>Add
                                                Workout</MenuItem>
                                            <MenuItem component={NavLink} to={'/addAuthor'} onClick={handleClose}>Add
                                                Author</MenuItem>
                                            <MenuItem component={NavLink} to={'/cleanup'}
                                                      onClick={handleClose}>Cleanup</MenuItem>
                                            <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                                        </Menu>
                                    </>
                                )}


                                {auth.userLevel === 'anonymous' && (
                                    <MenuItemManual active={classes.menuItemActive} caption={'Login'}
                                                    to={'/login'}
                                                    classes={classes.menuItem}/>)
                                }


                            </div>


                        </Toolbar>
                    </Container>


                </AppBar>
                <Container maxWidth="md">
                    {props.children}
                </Container>


            </>


        );
    } else if (props.type === 'nav') {
        return (
            <>
                <AppBar position={'sticky'} className={classes.appBar}>
                    <Container maxWidth={'md'}>
                        <Toolbar disableGutters variant={"dense"} className={classes.toolbar}>
                            <IconButton edge={'start'} color="inherit" component={NavLink} to={props.back}
                                        className={classes.naviButton}>
                                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                            </IconButton>
                            <Typography variant={"h6"} className={classes.title}>
                                {props.title}
                            </Typography>


                        </Toolbar>
                    </Container>
                </AppBar>
                <Container maxWidth="md">
                    {props.children}
                </Container>
            </>
        )
    }
}
