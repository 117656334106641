import React from 'react';
import Page from "../../Navigation/Page";

const Default = (props) => {
        return (
            <Page><div style={{textAlign: 'center'}}>

                <h1>404</h1>
                <h2>Page not found :(</h2>

            </div>
            </Page>
        )
    }
;

export default Default;
