import {createContext} from 'react';

const AuthContext = createContext(
    {
        userLevel: 'anonymous',
        token: null,
        login: () => {},
        logout: () => {}

    }
);


export {AuthContext};
